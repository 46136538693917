<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="isVisible"
    :before-close="handleClose"
    :detailId="detailId">
    <el-form ref="form" :model="chapterData" :rules="rules" label-width="80px">
      <el-row>

        <el-col :span="24">
          <el-form-item label="所属课程" prop="course_id">
            <template>
              <el-select v-model="pidValue" placeholder="请选择"  @change="handleChange">
                <el-option
                  v-for="item in pidOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row>
        <el-col :span="12">
          <el-form-item label="标题" prop="title">
            <el-input v-model="chapterData.title" placeholder="标题"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="12">
            <el-form-item label="排序" prop="sort_num">
              <el-input-number v-model="chapterData.sort_num" controls-position="right"></el-input-number>
            </el-form-item>
          </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="简介" prop="profile">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="简介"
              v-model="chapterData.profile">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import quillEditor from "@/components/Editor";
import tool from "@/utils/utils";
export default {
   name: "ChapterAddUpdate",
  data(){
      return {
        chapterData:{
          id:1,
          title:"",
          course_id:0,
          profile:"",
          sort_num:0,
        },
        rules:{
          course_id:[
            { required: true, message: '请选择课程', trigger: 'blur' },
          ],
          title:[
            { required: true, message: '请输入标题', trigger: 'blur' },
          ],
          profile:[
            { required: true, message: '请输入简介', trigger: 'blur' },
          ],
        },
        //分页数据
        pageInfo:{
          //当前页
          currentPage:1,
          //每页显示条数
          pageSize:-1,
        },
        pidValue:"",
        pidOptions:[
        ],
      }
  },
  //定义接受父组件传来的数据
  props:{
    isVisible:Boolean,
    title:String,
    detailId:Number,
    CourseList:Array,
  },
   methods:{
     query(){
       this.$http({
         method: 'post',
         url: '/course/list',
         data: {
           page:this.pageInfo.currentPage,
           size:this.pageInfo.pageSize,
         },
       }).then(res=>{
         if (res.data.code==0) {
           let handleData = tool.handleCourseData(res.data.data.list)
           console.log(handleData)
           this.pidOptions = handleData
         }else{
           this.$message.error(res.data.msg)
         }
       }).catch(err=>{
         console.log(err)
       })
     },
     //关闭之前的处理方法,向父组件传一个ChangVisible事件，false值,从而隐藏弹框
     handleClose(){
       this.$emit("ChangeVisible",false)
     },
     //根据id来判断是添加还是更新
     handleSubmit(){
       if (this.detailId!=0){ //更新
         this.$refs.form.validate((val)=>{
           if (!val){
             this.$message.error("请输入必填项")
             return false
           }
           let postData = this.chapterData
           this.$http({
             method: 'post',
             url: '/chapter/update',
             data: postData,
           }).then(res=>{
             if (res.data.code==0) {
               //清空表单
               this.$refs.form.resetFields()
               this.pidValue = 0
               this.pidOptions = [
               ]
               //关闭弹窗
               this.handleClose()
               //消息提示
               this.$message.success("操作成功")
               //子传父，调用父组件的query方法，重新加载数据
               this.$emit("query")
             }else{
               this.$message.error(res.data.msg)
             }
           }).catch(err=>{
             console.log(err)
           })
         })
       }else{ //添加
         this.$refs.form.validate((val)=>{
           if (!val){
             this.$message.error("请输入必填项")
             return false
           }
           let postData = this.chapterData
           this.$http({
             method: 'post',
             url: '/chapter/add',
             data: postData,
           }).then(res=>{
             if (res.data.code==0) {
               //清空表单
               this.$refs.form.resetFields()
               this.pidValue =0
               this.pidOptions = [
               ]
               //关闭弹窗
               this.handleClose()
               //消息提示
               this.$message.success("操作成功")
               //子传父，调用父组件的query方法，重新加载数据
               this.$emit("query")
             }else{
               this.$message.error(res.data.msg)
             }
           }).catch(err=>{
             console.log(err)
           })

         })
       }
     },
     //分类选择
     handleChange(val){
       this.chapterData.course_id = val
     },
     //清空表单数据
     handleFormData(){
       this.$refs.form.resetFields()
       this.pidValue = []
       this.query()
     },
   },
  watch:{
    detailId(val){
      if (val==0){
        return
      }
      //重新拉取一次分类列表
      if (this.pidOptions.length<1){
        this.query()
      }
      this.$http({
        method: 'post',
        url: '/chapter/detail',
        data: {
          id: val
        },
      }).then(res=>{
        if (res.data.code==0) {
            this.pidValue = res.data.data.course_id
            this.chapterData = res.data.data
        }else {
          this.$message.error(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    CourseList(){
      this.pidOptions = this.CourseList
      console.log("options",this.pidOptions)
    }
  },
  mounted(){

  }
}
</script>

<style scoped>

</style>
